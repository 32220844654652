import { enqueueSnackbar } from "notistack";
import { apiGet, apiPatch, apiPost } from "../../common/utils/request.utils";
import { AppDispatch } from "../../store";
import {
	getPricingError,
	resetPricings,
	setExportLoadingError,
	setExportLoadingState,
	setPricingLoadingState,
	transitionError,
	setLoader,
	setPricingRunLoader,
	pricingRunSuccess,
	PricingStatus,
	getPartiesSuccess,
	getPartiesError,
	setPricingTender,
	getPricingSuccess,
	getCancelledPricingsError,
	Pricing,
	setPricings,
	getGraphTypesSuccess,
	getGraphError,
} from "./pricingListSlice";
import {
	applyLinqPredicates,
	buildPredicates,
	setSelectedFilterValues,
} from "../filters/utils";
import { RootState } from "../../core/rootReducers";
import { selectPricingState } from "./pricingList.selector";
import {
	selectPersistedFilters,
	selectPricingFilters,
} from "../filters/filters.selector";
import * as liveUpdate from "../live_update/LiveUpdate.merge";
import * as _ from "lodash";
import { AxiosResponse } from "axios";
import {
	updatePricingTypeFilters,
	updateDeliveryPeriodsFilters,
	updatePartiesFilters,
} from "../filters/filters.thunk";

export function resetAllPricings() {
	return function (dispatch: AppDispatch) {
		dispatch(resetPricings());
	};
}

export function clearTransitionError() {
	return function (dispatch: AppDispatch) {
		dispatch(transitionError(undefined));
	};
}
export function getPricings(
	tenderId: number,
	page: number,
	per_page: number,
	latestUpdatedAtParam: Date | undefined = undefined
): any {
	return function (dispatch: AppDispatch, getState: any) {
		dispatch(setPricingLoadingState());
		const state = getState();
		const pricingQuery =
			state.filters.persistedFilters.pricings_query ?? "";
		return apiGet(
			`pricing?tender_id=${tenderId}&page=${page}&per_page=${per_page}&filters=${pricingQuery}` +
				(latestUpdatedAtParam
					? `&latest_updated_at=${latestUpdatedAtParam}`
					: "")
		).then(
			(p) => {
				if (page === 1 && !latestUpdatedAtParam) {
					dispatch(resetPricings());
				}
				return dispatch(
					getPricingSuccess({
						pricings: p?.data?.data,
						latestUpdatedAt: p?.data?.max_updated_at_pricing_date,
						latestUpdatedAtParam,
						hasNextPage: !!p?.data?.has_next_page,
					})
				);
			},
			(error) => {
				return dispatch(getPricingError(error.message));
			}
		);
	};
}

export function getParties(search: string = ""): any {
	return function (dispatch: AppDispatch) {
		return apiPost(`external-services/parties`, { search: search }).then(
			(parties) => {
				return dispatch(getPartiesSuccess(parties.data?.partyResults));
			},
			(error) => {
				return dispatch(getPartiesError(error.message));
			}
		);
	};
}

export function downloadPricingRunsExport(filters: string) {
	return function (dispatch: AppDispatch) {
		dispatch(setExportLoadingState(true));
		return apiGet(`pricing-run/export?filters=${filters}`)
			.then((response) => {
				window.open(response.data.url);
			})
			.catch((err) => {
				enqueueSnackbar("Something went wrong", {
					variant: "error",
					autoHideDuration: 3000,
				});
				dispatch(setExportLoadingError(err));
			})
			.finally(() => dispatch(setExportLoadingState(false)));
	};
}

export function donwloadPricingPbInputs(pricingRunId: number) {
	return function (dispatch: AppDispatch) {
		dispatch(setExportLoadingState(true));
		return apiGet(`pricing-run/${pricingRunId}/pb-params`)
			.then((response) => {
				if (response?.data?.url) window.open(response.data.url);
			})
			.catch((err) => {
				enqueueSnackbar("Something went wrong", {
					variant: "error",
					autoHideDuration: 3000,
				});
				dispatch(setExportLoadingError(err));
			})
			.finally(() => dispatch(setExportLoadingState(false)));
	};
}

export function downloadPricingsExport(
	tender_id: number,
	statusIn: PricingStatus[] = [],
	format: string = "",
	forPricingsFilters: string | undefined = undefined
) {
	return function (dispatch: AppDispatch, getState: any) {
		dispatch(setExportLoadingState(true));
		const state = getState();
		const statusQueryParams = statusIn
			.map((status) => `status_in=${status}`)
			.join("&");
		const queries = [];
		if (state.filters.persistedFilters.pricings_query?.length) {
			queries.push(
				JSON.parse(state.filters.persistedFilters.pricings_query)
			);
		}

		if (forPricingsFilters?.length) {
			queries.push(forPricingsFilters);
		}

		const filters = JSON.stringify(queries.join(" and "));
		return apiGet(
			`pricing/export/${tender_id}?format=${format}&filters=${filters}${
				statusQueryParams ? "&" + statusQueryParams : ""
			}`
		)
			.then((response) => {
				if (response.data.msg) {
					enqueueSnackbar(response.data.msg, {
						variant: "warning",
						autoHideDuration: 5000,
					});
				} else {
					window.open(response.data.url);
				}
			})
			.catch((err) => {
				enqueueSnackbar("Something went wrong", {
					variant: "error",
					autoHideDuration: 3000,
				});
				dispatch(setExportLoadingError(err));
			})
			.finally(() => dispatch(setExportLoadingState(false)));
	};
}

export function submitTransition(endpoint: string, data: any) {
	return function (dispatch: AppDispatch) {
		dispatch(clearTransitionError());
		dispatch(setLoader({ name: "transition", value: true }));
		return apiPatch(endpoint, data)
			.then(() => {
				dispatch(setLoader({ name: "transition", value: false }));
			})
			.catch((err) => {
				enqueueSnackbar("Something went wrong", {
					variant: "error",
					autoHideDuration: 3000,
				});
				dispatch(transitionError(err?.response?.data));
			});
	};
}

export function loadPricingRuns(pricingId: number, page: number) {
	return async function (dispatch: AppDispatch) {
		dispatch(setPricingRunLoader({ pricingId, isLoading: true }));
		try {
			const response = await apiGet(
				`pricing-run?pricing_id=${pricingId}&page=${page}&per_page=5`
			);
			dispatch(
				pricingRunSuccess({ pricingId, runs: response.data.data })
			);
		} catch (error: any) {
			dispatch(getPricingError(error.message));
		} finally {
			dispatch(setPricingRunLoader({ pricingId, isLoading: false }));
		}
	};
}

export function getTender(id: number): any {
	return function (dispatch: AppDispatch) {
		const tenderUrl = `tenders/${id}`;
		dispatch(setLoader({ name: "tender", value: true }));
		return apiGet(tenderUrl)
			.then((response) => {
				return dispatch(setPricingTender(response.data));
			})
			.finally(() => {
				dispatch(setLoader({ name: "tender", value: false }));
			});
	};
}

export function mergeincomingPricings(
	tenderId: number,
	incomingPricings: Pricing[]
) {
	return function (dispatch: AppDispatch, getState: () => RootState) {
		const pricings = selectPricingState(getState()).pricings;

		const persistedFilters = selectPersistedFilters(getState());
		const pricingsFiltersData = selectPricingFilters(getState());

		const values = setSelectedFilterValues(
			pricingsFiltersData,
			persistedFilters.filters?.pricings_filters ?? ""
		);
		const predicates = buildPredicates(
			pricingsFiltersData,
			values,
			"Pricings"
		);

		const filteredPricings = liveUpdate.mergeIncomingPricings(
			pricings,
			incomingPricings.filter(
				(pricing) => pricing.tender_id === tenderId
			),
			[],
			predicates.linqPredicates
		);

		if (!_.isEqual(filteredPricings, pricings)) {
			dispatch(setPricings(filteredPricings));
		}
		// TODO, seulement si UNDER_PRICING ?
		dispatch(updatePricingTypeFilters(incomingPricings));
		dispatch(updateDeliveryPeriodsFilters(incomingPricings));
		dispatch(updatePartiesFilters(incomingPricings));
	};
}

export function mergeincomingUpdatedPricings(
	tenderId: number,
	incomingPricings: Pricing[]
) {
	return function (dispatch: AppDispatch, getState: () => RootState) {
		const persistedFilters = selectPersistedFilters(getState());
		const pricingsFiltersData = selectPricingFilters(getState());

		const values = setSelectedFilterValues(
			pricingsFiltersData,
			persistedFilters.filters?.pricings_filters ?? ""
		);
		const predicates = buildPredicates(
			pricingsFiltersData,
			values,
			"Pricings"
		);

		const pricings = selectPricingState(getState()).pricings;
		const pricingIds = new Set(pricings.map((pricing) => pricing.id));
		const pricingInterSection = incomingPricings.filter((pricing) =>
			pricingIds.has(pricing.id)
		);

		const pricingNotInInterSection = incomingPricings.filter(
			(pricing) => !pricingIds.has(pricing.id)
		);

		// Maybe there are updated pricings that before were not matching filters. Add them
		pricingNotInInterSection.forEach((p) => {
			const pricingCompliesWithFilters = applyLinqPredicates(
				[p],
				predicates.linqPredicates
			);
			if (pricingCompliesWithFilters.length > 0) {
				pricingInterSection.push(pricingCompliesWithFilters[0]);
			}
		});

		if (pricingInterSection.length) {
			dispatch(mergeincomingPricings(tenderId, pricingInterSection));
		}
	};
}

export function getCancelledPricings(url: string, tenderId?: string): any {
	return function (dispatch: AppDispatch) {
		return apiGet(url).then(
			(p) => {
				const pricings = p?.data?.data
					.map((item: Pricing) => {
						item.updated_at = item.updated_at + "Z";
						item.isNewUpdate = true;
						return item;
					})
					.filter(
						(pric: Pricing) => pric.tender_id === Number(tenderId)
					);
				return dispatch(
					mergeincomingPricings(Number(tenderId) || 0, pricings)
				);
			},
			(error) => {
				return dispatch(getCancelledPricingsError(error.message));
			}
		);
	};
}

export function getGraphTypes(group_id: number): any {
	return async function (dispatch: AppDispatch) {
		try {
			const response = await apiGet(`timeseries/types/${group_id}`);
			dispatch(getGraphTypesSuccess(response.data.ts));
		} catch (error: any) {
			dispatch(getGraphError(error.message));
		}
	};
}

export function getGraphData(id: number, kinds: string): any {
	return async function () {
		const listKinds: string[] = kinds.split(",");
		let installedCapa: number = 0;
		let horizon: { start_date: string; end_date: string } | undefined =
			undefined;
		let result: { [key: string]: any } = {};
		let l: Promise<AxiosResponse>[] = [];

		listKinds.forEach((t: string) => {
			l.push(apiGet(`timeseries/${id}?kinds=${t}`));
		});
		let values = await Promise.all(l);
		values.forEach((response: AxiosResponse) => {
			listKinds.forEach((t: string) => {
				if (response.data[t] && response.data[t].length > 0) {
					result[t] = response.data[t];
					installedCapa = response.data?.installed_capacity as number;
					horizon = response.data?.horizon;
				}
				result.error = response.data.error;
			});
		});
		return { result, installedCapa, horizon };
	};
}
