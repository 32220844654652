import { flattenDepthFirst, visitDepthFirst } from "../../common/utils/tree";
import { RootState } from "../../core/rootReducers";
import { GroupingField } from "../metadata/metadata.module";
import {
	childrenAccessor,
	groupOfPricingFilter,
	isGroup,
	isPricing,
	PricingListItem,
} from "../pricing_groups/groupUtils";
import { Pricing } from "../pricing_list/pricingListSlice";
import { GroupedField, GroupedResponseCount } from "./grouping.module";
import * as _ from "lodash";

export function selectPricingsGrouping(state: RootState) {
	return state.grouping.pricings;
}

export function selectPricingSelectedGroupingFields(
	state: RootState
): GroupingField[] {
	return selectPricingsGrouping(state).selectedGroups;
}

export function selectPricingsGroupCountIsLoading(state: RootState): boolean {
	return selectPricingsGrouping(state).count.isLoading;
}

export function selectPricingsGroupCountError(state: RootState) {
	return selectPricingsGrouping(state).count.error;
}

export function selectPricingsGroupCount(
	state: RootState
): GroupedResponseCount | null {
	return selectPricingsGrouping(state).count.data;
}

export function selectSelectedGroups(state: RootState) {
	const selectedGroups: GroupedField[] = [];
	state.grouping.pricings.count.data?.forEach((root) => {
		visitDepthFirst(
			root,
			(group) => {
				if (group.isSelected) {
					selectedGroups.push(group);
				}
			},
			(group) => group.children,
			isGroup
		);
	});
	return selectedGroups;
}

export function selectSelectedPricing(state: RootState) {
	const selectedPricings: Pricing[] = [];
	state.grouping.pricings.count.data?.forEach((root) => {
		visitDepthFirst(
			root,
			(group) => {
				group.pricings?.forEach((pricing) => {
					if (pricing.isSelected) {
						selectedPricings.push(pricing);
					}
				});
			},
			(group) => group.children,
			isGroup
		);
	});
	return selectedPricings;
}

export function selectFlattenedGroups(state: RootState) {
	const pricingsGroupsCount = state.grouping.pricings.count.data;
	const ret = _.flatten(
		(pricingsGroupsCount || []).map((node) =>
			flattenDepthFirst<PricingListItem>(
				node,
				childrenAccessor,
				groupOfPricingFilter
			)
		)
	);
	return ret;
}

export function selectAllPricings(state: RootState) {
	return selectFlattenedGroups(state)
		.map((item) => item.node)
		.filter(isPricing);
}
