import _ from "lodash";
import { Formik, getIn } from "formik";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { PrimaryButton } from "../../../../../common/components/CustomButton";
import { FluidRadioGroup } from "../../../../../common/components/FluidRadioGroup";
import { noop } from "../../../../../common/utils/operations";
import {
	ClickUnit,
	ContractPeriodType,
} from "../../../../../requests_geco/contractsApi/contractsApi.types";
import { Box, CircularProgress } from "@mui/material";
import { style } from "./ClickModal.style";
import { FluidButton } from "../../../../../common/components/FluidButton";
import { clickValidator } from "../../../formik/clickabilityTabValidators";
import {
	ClickFormik,
	clickFormikInitalValueMapper,
} from "../../../formik/clickabilityTabFormik";
import { Spacer } from "../../../../../common/components/Spacer";
import { FluidTextField } from "../../../../../common/components/FluidTextField";
import { FluidNumber } from "../../../../../common/components/FluidNumberField";
import FluidDatePicker from "../../../../../common/components/FluidDatePicker";
import {
	ClickTypesResponseType,
	OtClickTypes,
} from "../../../../../requests_geco/referentialApi/referentialApi.types";
import { useCallback, useMemo, useState } from "react";
import { If } from "../../../../../common/components/If";
import { ErrorText } from "../../../../../common/components/ErrorText";
import Group from "../../../../../common/components/Group";
import FluidSelect from "../../../../../common/components/FluidSelect";
import {
	MapDayMonthYearToYearMonthDay,
	mapYearMonthDayToDayMonthYear,
	TimePeriodOptions,
	timePeriodOptions,
} from "../../../../../common/utils/timePeriodOptions";

interface ClickModalProps {
	isOpen: boolean;
	createClickErrorMessage: string | string[];
	contractPeriod: ContractPeriodType;
	clickTypes: ClickTypesResponseType[];
	isLoading?: boolean;
	handleClose: () => void;
	onCreateClick: (click: ClickFormik) => void;
	newClickId: number;
}

export default function ClickModal(props: ClickModalProps) {
	// To allow traders to submit clicks one after the other in rapid succession
	const [formikHistory, setFormikHistory] = useState<
		ClickFormik | undefined
	>();
	const clickTypeOptions = useMemo(() => {
		return props.clickTypes.map((clickType) => ({
			value: `${clickType.id}`,
			label: `${clickType.name[0].toUpperCase()}${clickType.name
				.substring(1)
				.toLowerCase()}`,
		}));
	}, [props.clickTypes]);

	const clickPeriod: OtClickTypes | undefined = useMemo(
		() =>
			props.contractPeriod?.clickability?.clickability_information
				?.click_period_frequency,
		[props.contractPeriod]
	);

	const memoTimePeriodOptions: TimePeriodOptions = useMemo(() => {
		if (
			props.contractPeriod?.start_date &&
			props.contractPeriod?.end_date
		) {
			return timePeriodOptions(
				mapYearMonthDayToDayMonthYear(props.contractPeriod.start_date),
				mapYearMonthDayToDayMonthYear(props.contractPeriod.end_date),
				true
			);
		}
		return {
			[OtClickTypes.CalYear]: [],
			[OtClickTypes.Month]: [],
			[OtClickTypes.Quarter]: [],
			[OtClickTypes.TradePeriod]: [],
		};
	}, [props.contractPeriod]);

	const getClickTypeFromId = useCallback(
		(id: string | undefined): undefined | OtClickTypes => {
			const value = _.find(
				props.clickTypes || [],
				// @ts-ignore
				(clickType) => clickType.id === Number(id)
			)?.ot_name;
			return value;
		},
		[props.clickTypes]
	);

	const getTypeIdFromClickType = useCallback(
		(type: OtClickTypes | undefined): undefined | string => {
			const value = _.find(
				props.clickTypes || [],
				// @ts-ignore
				(clickType) => clickType.ot_name === type
			)?.id;
			return value;
		},
		[props.clickTypes]
	);

	const onSubmit = useCallback(
		(formikData: ClickFormik) => {
			setFormikHistory(formikData);
			props.onCreateClick(formikData);
		},
		[formikHistory, props.contractPeriod, props.onCreateClick]
	);

	const { isOpen, handleClose } = props;

	return (
		<Dialog maxWidth="lg" onClose={handleClose} open={isOpen}>
			<DialogTitle>{"Create Click"}</DialogTitle>
			<Formik
				validateOnChange={false}
				validateOnBlur={false}
				validationSchema={clickValidator}
				enableReinitialize
				initialValues={clickFormikInitalValueMapper(
					props.contractPeriod,
					getTypeIdFromClickType(clickPeriod),
					props.newClickId,
					formikHistory
				)}
				onSubmit={onSubmit}
			>
				{({
					handleChange,
					handleSubmit,
					setFieldValue,
					values,
					errors,
				}) => (
					<form onSubmit={handleSubmit}>
						<DialogContent>
							<Box sx={style.container}>
								<FluidRadioGroup
									orientation="row"
									name={"type_id"}
									errorMessage={getIn(errors, "type_id")}
									value={`${values.type_id}`}
									title={"Type"}
									onChange={noop}
									options={clickTypeOptions}
									isDisabled
								/>

								<Spacer gap={24} />

								<Group>
									<If
										condition={[
											OtClickTypes.Month,
											OtClickTypes.Quarter,
											OtClickTypes.CalYear,
										].includes(
											getClickTypeFromId(
												values.type_id
											) as OtClickTypes
										)}
									>
										<FluidSelect
											items={
												_.get(
													memoTimePeriodOptions,
													getClickTypeFromId(
														values.type_id
													) || ""
												) || []
											}
											label="Period"
											name="period"
											// @ts-ignore
											onChange={(val: string) => {
												const [startDate, endDate] =
													val.split("@");
												const clickStart =
													MapDayMonthYearToYearMonthDay(
														startDate
													);
												const clickEnd =
													MapDayMonthYearToYearMonthDay(
														endDate
													);
												setFieldValue("period", val);

												setFieldValue(
													"click_start",
													clickStart
												);

												setFieldValue(
													"click_end",
													clickEnd
												);
											}}
											isLabelStatic
											errorMessage={getIn(
												errors,
												"period"
											)}
											//@ts-ignore
											value={values.period}
										/>
									</If>

									<FluidDatePicker
										onChange={handleChange}
										name={"click_start"}
										label={"Start Date"}
										isReadOnly={
											OtClickTypes.TradePeriod !=
											getClickTypeFromId(values.type_id)
										}
										value={values.click_start}
										errorMessage={errors.click_start}
									/>

									<FluidDatePicker
										onChange={handleChange}
										name={"click_end"}
										label={"End Date"}
										isReadOnly={
											OtClickTypes.TradePeriod !=
											getClickTypeFromId(values.type_id)
										}
										value={values.click_end}
										errorMessage={errors.click_end}
									/>
								</Group>
								<Spacer gap={24} />
								<FluidTextField
									onChange={handleChange}
									value={values.name}
									name={"name"}
									label={"Name"}
									errorMessage={getIn(errors, "name")}
								/>
								<Spacer gap={24} />
								<Group>
									<FluidNumber
										onChange={handleChange}
										errorMessage={getIn(errors, "price")}
										name={"price"}
										title={"Price"}
										value={
											values.price as unknown as number
										}
									/>
									<FluidNumber
										onChange={handleChange}
										errorMessage={getIn(
											errors,
											"mid_price"
										)}
										name={"mid_price"}
										title={"Mid Price"}
										value={
											values.mid_price as unknown as number
										}
									/>
								</Group>
								<Spacer gap={24} />
								<Group>
									<If
										condition={
											values.click_unit === undefined ||
											values.click_unit === ClickUnit.MWH
										}
									>
										<FluidNumber
											onChange={handleChange}
											errorMessage={getIn(
												errors,
												"volume"
											)}
											name={"volume"}
											title={"Volume"}
											value={
												values.volume as unknown as number
											}
										/>
									</If>
									<If
										condition={
											values.click_unit ===
											ClickUnit.PERCENTAGE
										}
									>
										<Box sx={{ width: "162px" }}>
											<FluidNumber
												title="Volume"
												name="volumePct"
												onChange={handleChange}
												errorMessage={getIn(
													errors,
													"volumePct"
												)}
												value={getIn(
													values,
													"volumePct"
												)}
											/>
										</Box>
									</If>
									<FluidRadioGroup
										orientation="row"
										name={"click_unit"}
										errorMessage={getIn(
											errors,
											"click_unit"
										)}
										value={`${values.click_unit}`}
										title={"Click Unit"}
										onChange={(value: unknown | string) =>
											setFieldValue("click_unit", value)
										}
										options={[
											{
												label: ClickUnit.MWH,
												value: ClickUnit.MWH,
											},
											{
												label: ClickUnit.PERCENTAGE,
												value: ClickUnit.PERCENTAGE,
											},
										]}
									/>
								</Group>
								<Spacer gap={24} />
								<FluidDatePicker
									onChange={handleChange}
									name={"click_date"}
									label={"Click Date"}
									value={values.click_date}
									errorMessage={getIn(errors, "click_date")}
								/>
								<Spacer gap={24} />
								<If condition={!!props.createClickErrorMessage}>
									<ErrorText>
										{props.createClickErrorMessage}
									</ErrorText>
									<Spacer gap={24} />
								</If>
							</Box>
						</DialogContent>

						<DialogActions>
							<If condition={!props.isLoading}>
								<PrimaryButton
									onClick={handleClose}
									text="Cancel"
									type="button"
									color="secondary"
								/>
								<FluidButton
									type="submit"
									icon={"add_circle_outline"}
									label={"Add"}
									onClick={noop}
								/>
							</If>
							<If condition={!!props.isLoading}>
								<CircularProgress />
							</If>
						</DialogActions>
					</form>
				)}
			</Formik>
		</Dialog>
	);
}
