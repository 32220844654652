import SimpleTable, {
	HeadersType,
} from "../../../../common/components/SimpleTable/SimpleTable";
import {
	clickabilitySortableColumns,
	useLazyGetContractPeriodClickObjectsQuery,
} from "../../../../requests_geco/contractsApi";
import CircularProgress from "@mui/material/CircularProgress";
import YesNoTag from "../../../../common/components/YesNoTag";
import { Spacer } from "../../../../common/components/Spacer";
import React, { useEffect, useMemo, useState } from "react";
import {
	ClickUnit,
	ContractDetailType,
	ContractPeriodClickObjectType,
	ContractPeriodType,
} from "../../../../requests_geco/contractsApi/contractsApi.types";
import { Box } from "@mui/material";
import { FluidButton } from "../../../../common/components/FluidButton";
import InfoBlock from "../../../../common/components/InfoBlock/InfoBlock";
import ClickabilityModal from "./ClickabilityModal";
import { style } from "./ClickabilityTab.style";
import ClickModal from "./ClickModal";
import { NJButton } from "@engie-group/fluid-design-system-react";
import { useGetClickTypesQuery } from "../../../../requests_geco/referentialApi/referentialApi";
import ClickabilityTabFilters from "./ClickabilityTabFilters";
import { useCreateContractPeriodClickObjectMutation } from "../../../../requests_geco/contractsApi/contractsApi";
import { ClickFormik } from "../../formik/clickabilityTabFormik";
import { FieldValue } from "../../../../common/components/FiltersDrawer/FiltersDrawer.type";
import {
	ErrorType,
	formatApiErrorMessage,
} from "../../../../common/utils/formatApiErrorMessage";
import { useRtkQueryDynamicEndpoint } from "../../../../common/hooks/useRtkQueryDynamicEndpoint";
import { useIsUserWithinGroups } from "../../../../common/hooks/useIsUserWithinGroups";
import { Groups } from "../../../authentication/authentication.constant";
import { PageInfoType } from "../../../../requests_geco/gecoTypes";

export interface ClickabilityTabProps {
	contract?: ContractDetailType;
	contractPeriodId: number;
	onSaveDraftContract: (contract: ContractDetailType) => void;
}

const ClickabilityTab = ({
	contract,
	contractPeriodId,
	onSaveDraftContract,
}: ClickabilityTabProps) => {
	const [pageInfo, setPageInfo] = useState<PageInfoType>({
		page: 1,
	});
	const [isClickabilityModalOpen, setClickabilityModalOpen] = useState(false);
	const [isClickModalOpen, setClickModalOpen] = useState(false);

	const [filtersOpen, setFiltersOpen] = useState(false);
	const [filters, setFilters] = useState<Record<string, FieldValue>>({});
	const [
		createClickBase,
		{
			isLoading: isCreatingClick,
			error: clickPostError,
			isSuccess: isClickSuccess,
		},
	] = useCreateContractPeriodClickObjectMutation();

	const createClick = useRtkQueryDynamicEndpoint(createClickBase);

	const { data: clickTypes } = useRtkQueryDynamicEndpoint(
		useGetClickTypesQuery
	)({});
	const { isUserAuthorized } = useIsUserWithinGroups();
	const [getClickObjectsBase, { data, isLoading, isFetching }] =
		useLazyGetContractPeriodClickObjectsQuery();

	const getClickObjects = useRtkQueryDynamicEndpoint(getClickObjectsBase);

	const isUserAuthorizedBool = isUserAuthorized([
		Groups.geco_admin,
		Groups.geco_trader,
	]);

	useEffect(() => {
		getClickObjects({
			contract_period_id: contractPeriodId,
			...filters,
			...pageInfo,
		});
	}, [pageInfo, filters]);

	const clickObjects =
		data?.result.map((click) => ({
			...click,
			ot_sync: <YesNoTag yes={click.ot_sync} />,
			type_id:
				clickTypes?.find(
					(ct) => String(ct.id) === String(click.type_id)
				)?.name ?? click.type_id,
		})) ?? [];

	const contractPeriod: ContractPeriodType | undefined = useMemo(() => {
		return contract?.contract_periods.filter(
			(cp: ContractPeriodType) => cp.id === Number(contractPeriodId)
		)[0];
	}, [contract]);

	useEffect(() => {
		if (isClickSuccess) {
			setClickModalOpen(false);
		}
	}, [isClickSuccess]);

	const headers: HeadersType<ContractPeriodClickObjectType>[] = [
		{ label: "Click Date", accessor: "click_date" },
		{ label: "Click Start", accessor: "click_start" },
		{ label: "Click End", accessor: "click_end" },
		{ label: "Type", accessor: "type_id" },
		{ label: "Price", accessor: "price" },
		{ label: "Mid Price", accessor: "mid_price" },
		{ label: "Volume %", accessor: "volume_percentage" },
		{ label: "OT Sync", accessor: "ot_sync" },
		{ label: "Last Sync", accessor: "last_sync_update" },
	].map((header) => ({
		...header,
		sortable: clickabilitySortableColumns.includes(header.accessor),
	})) as HeadersType<ContractPeriodClickObjectType>[];

	return (
		<>
			<Spacer gap={24} />
			<Box sx={style.container.clickability}>
				<InfoBlock
					info={[
						{
							label: "Click Period",
							value: contractPeriod?.clickability
								?.clickability_information
								?.click_period_frequency,
						},
						{
							label: "Click Type",
							value: contractPeriod?.clickability
								?.clickability_information?.type,
						},
						{
							label: "Max Capacity",
							value: contractPeriod?.clickability?.max_capacity,
						},
						{
							label: "Min Capacity",
							value: contractPeriod?.clickability?.min_capacity,
						},
						{
							label: "Max Pcr Clickable",
							value: contractPeriod?.clickability
								?.max_click_percentage,
						},
						{
							label: "Min Pcr Clickable",
							value: contractPeriod?.clickability
								?.min_click_percentage,
						},
						{
							label: "Alpha",
							value: contractPeriod?.clickability
								?.clickability_information?.alpha?.commodity,
						},
						{
							label: "Beta",
							value: contractPeriod?.clickability
								?.clickability_information?.beta?.commodity,
						},
						{
							label: "Beta Fees",
							value: (
								contractPeriod?.clickability
									?.clickability_information?.beta?.fees || []
							).reduce(
								(accumulator, currentValue) =>
									accumulator + currentValue.value,
								0
							),
						},
					]}
				/>
				<Box sx={style.container.clickability.edit}>
					<FluidButton
						icon={"mode_edit"}
						label={"Edit Clickability"}
						onClick={() => setClickabilityModalOpen(true)}
						isDisabled={!isUserAuthorizedBool}
					/>
				</Box>
			</Box>
			<Spacer gap={24} />
			{isLoading && <CircularProgress />}
			{clickObjects && !isLoading && (
				<Box>
					<Spacer gap={24} />
					<Box sx={style.buttonWrapper}>
						<NJButton
							icon="add_circle_outline"
							label="Add Click"
							emphasis="subtle"
							onClick={() => setClickModalOpen(true)}
							isDisabled={!isUserAuthorizedBool}
						/>
						<FluidButton
							label={"Filters"}
							icon="filter_list"
							onClick={() => setFiltersOpen(true)}
						/>
					</Box>
					<Spacer gap={24} />

					<ClickabilityTabFilters
						clickTypes={clickTypes || []}
						isOpen={filtersOpen}
						defaultFilters={filters}
						onClose={() => setFiltersOpen(false)}
						onApplyFilters={setFilters}
					/>
					<SimpleTable
						dataTestid="clickabilityTable"
						headers={headers}
						items={clickObjects}
						isFetching={isFetching}
						pageInfo={pageInfo}
						setPageInfo={setPageInfo}
						infiniteScroll
					/>
				</Box>
			)}
			<ClickabilityModal
				isOpen={isClickabilityModalOpen}
				handleClose={() => setClickabilityModalOpen(false)}
				onSaveDraftContract={onSaveDraftContract}
				contract={contract}
				contractPeriodId={contractPeriodId}
			/>
			{!!contractPeriod && (
				<ClickModal
					isOpen={isClickModalOpen}
					isLoading={isCreatingClick}
					createClickErrorMessage={formatApiErrorMessage(
						clickPostError as ErrorType
					)}
					clickTypes={clickTypes || []}
					handleClose={() => setClickModalOpen(false)}
					onCreateClick={(newClick: ClickFormik) => {
						const formattedClick = {
							...newClick,
							type_id: newClick.type_id
								? Number(newClick.type_id)
								: undefined,
							volume: Number(
								newClick.click_unit === ClickUnit.PERCENTAGE
									? newClick.volumePct
									: newClick.volume
							),
						};

						delete formattedClick.volumePct;

						createClick({
							...formattedClick,
							contract_period_id: contractPeriodId,
						});
					}}
					contractPeriod={contractPeriod}
					newClickId={clickObjects.length + 1}
				/>
			)}
		</>
	);
};

export default ClickabilityTab;
