import Enumerable from "linq";
import { Filter, FilterType } from "../metadata/metadata.module";
import { addDays, addYears, addMonths, addWeeks, format } from "date-fns";
import { Identity } from "../authentication/authSlice";

export const linqPropertyAccessors = (page: string, fieldKey: string) => {
	if (page === "Tenders") {
		switch (fieldKey) {
			case "installed_capacity_mw":
				return (item: any) => item.installed_capacity_mw;
			case "deadline":
				return (item: any) =>
					new Date(`${item.due_date}T00:00:00`).getTime();
			case "validity_date":
				return (item: any) =>
					new Date(`${item.validity_date}T00:00:00`).getTime();
			case "name":
				return (item: any) => item.name.toLowerCase();
			case "account":
				return (item: any) => item.account.name.toLowerCase();
			case "country":
				return (item: any) =>
					item.countries.length >= 0
						? item.countries[0].toLowerCase()
						: "";
			case "tenders_status":
				return (item: any) => item.status.toLowerCase();
			case "tenders_status_not_in":
				return (item: any) => item.status.toLowerCase();
			case "pricings_status":
				return (item: any) => item.pricing_statuses || [];
			case "technology":
				return (item: any) =>
					item.technologies.length >= 0
						? item.technologies[0].toLowerCase()
						: "";
			case "originator":
				return (item: any) => item.originator.id.toString();
			case "trader":
				return (item: any) => item.trader.id.toString();
		}
	}

	if (page === "Pricings") {
		switch (fieldKey) {
			case "status":
				return (item: any) => item.status.toLowerCase();
			case "status_not_in":
				return (item: any) => item.status.toLowerCase();
			case "result_option":
				return (item: any) => item.result_option.toLowerCase();
			case "portfolio":
				return (item: any) =>
					item.portfolio_id ? item.portfolio_id.toString() : "";
			case "party":
				return (item: any) =>
					item.party ? item.party.name.toLowerCase() : "";
			case "parties":
				return (item: any) =>
					item.parties.map((pricingParty: any) =>
						pricingParty.party_id.toString()
					);
			case "pricing_date":
				return (item: any) =>
					new Date(
						`${item.active_run_pricing_date}T00:00:00`
					).getTime();
			case "validity_date":
				return (item: any) =>
					new Date(`${item.validity_date}T00:00:00`).getTime();
			case "pricing_type":
				return (item: any) => item.pricing_type_id.toString();
			case "delivery-periods":
				return (item: any) =>
					item.delivery_period.toString().toLowerCase();
			case "site":
				return (item: any) =>
					item.site_pricings[0]?.site?.id.toString();
			case "technology":
				return (item: any) => item.technology_id.toString();
			case "technology_group":
				return (item: any) => item.technology_id;
			case "in_use":
				return (item: any) => item.in_use.toString();
			case "pricings_status":
				return (item: any) => item.status.toLowerCase() || "";
		}
	}

	return (item: any) => item[fieldKey];
};

export const isMatchingAgainstArray = (page: string, fieldKey: string) => {
	if (page === "Tenders") {
		switch (fieldKey) {
			case "pricings_status":
				return true;
		}
	} else if (page === "Pricings") {
		switch (fieldKey) {
			case "parties":
				return true;
		}
	}
	return false;
};

export const linqValueAccessors = (
	page: string,
	fieldKey: string,
	values: any[]
) => {
	if (page === "Tenders") {
		switch (fieldKey) {
			case "technology":
				return values.map((m: any) => m.name.toLowerCase());
		}
	}

	if (page === "Pricings") {
		switch (fieldKey) {
			case "technology_group":
				return values.map((m: any) => m.toString());
		}
	}
	return values.map((m: any) => m.id.toString().toLowerCase());
};

const convertValue = (f: Filter, val: Date | string | number) => {
	if (f.filter_type === FilterType.date) {
		const asDate = val instanceof Date ? val : new Date(val);
		return asDate.toISOString().split("T")[0];
	}

	return val;
};

const convertToLinqValue = (f: Filter, val: Date | string | number) => {
	if (f.filter_type === FilterType.date) {
		const sDate = new Intl.DateTimeFormat("en-US", {
			year: "numeric",
			month: "numeric",
			day: "numeric",
		}).format(new Date(val));
		return new Date(sDate).getTime();
	}

	return Number(val);
};

export const getNumericPredicate = (
	page: string,
	f: Filter,
	val: string[] | string
) => {
	const propertyAccessor = linqPropertyAccessors(page, f.field_key);
	if (val && !Array.isArray(val)) {
		const predicate = `${f.field_path} eq ${convertValue(f, val)}`;
		const linqPredicate = (item: any) =>
			propertyAccessor(item) === convertToLinqValue(f, val);
		return { predicate, linqPredicate };
	}

	if (val[0] && val[1]) {
		const predicate = `(${f.field_path} ge ${convertValue(f, val[0])} and ${
			f.field_path
		} le ${convertValue(f, val[1])})`;
		const linqPredicate = (item: any) =>
			propertyAccessor(item) >= convertToLinqValue(f, val[0]) &&
			propertyAccessor(item) <= convertToLinqValue(f, val[1]);
		return { predicate, linqPredicate };
	}

	if (val[0]) {
		const predicate = `${f.field_path} ge ${convertValue(f, val[0])}`;
		const linqPredicate = (item: any) =>
			propertyAccessor(item) >= convertToLinqValue(f, val[0]);
		return { predicate, linqPredicate };
	}

	if (val[1]) {
		const predicate = `${f.field_path} le ${convertValue(f, val[1])}`;
		const linqPredicate = (item: any) =>
			propertyAccessor(item) <= convertToLinqValue(f, val[1]);
		return { predicate, linqPredicate };
	}
};

export const getListPredicate = (f: Filter, val: string[]) => {
	const ids = val.map((v: any) => `'${v.id}'`);

	if (ids.length > 0) {
		if (ids.length === 1) {
			if (f.filter_type === FilterType.list_not_in) {
				return `${f.field_path} in (${ids.join(",")},) eq false`;
			} else {
				return `${f.field_path} in (${ids.join(",")},)`;
			}
		}
		if (f.filter_type === FilterType.list_not_in) {
			return `${f.field_path} in (${ids.join(",")}) eq false`;
		} else {
			return `${f.field_path} in (${ids.join(",")})`;
		}
	}
};

export const applyLinqPredicates = (items: any[], predicates: any[]): any[] => {
	let query = Enumerable.from(items);
	predicates.forEach((predicate) => {
		query = query.where(predicate);
	});
	return query.toArray();
};

export const getExactLinqPredicate = (
	page: string,
	filter: Filter,
	value: any
) => {
	const propertyAccessor = linqPropertyAccessors(page, filter.field_key);
	return (item: any) => propertyAccessor(item) === value;
};

export const getAlphaNumericLingPredicate = (
	page: string,
	filter: Filter,
	value: string
) => {
	const propertyAccessor = linqPropertyAccessors(page, filter.field_key);

	if (page === "Pricings") {
		switch (filter.field_key) {
			case "site":
				return (item: any) =>
					item.site_pricings.some((site_pricing: any) =>
						site_pricing.site.name
							.toLowerCase()
							.includes(value.toLowerCase())
					);
		}
	}
	return (item: any) =>
		("" + propertyAccessor(item))
			.toLowerCase()
			.includes(("" + value).toLowerCase());
};

export type LinqPredicate = (item: any) => boolean;
export const buildPredicates = (
	filtersData: Filter[],
	values: any,
	page: string
): { predicates: string[]; linqPredicates: LinqPredicate[] } => {
	const predicates: string[] = [];
	const linqPredicates: any[] = [];
	filtersData.forEach((f: Filter) => {
		if (!values.hasOwnProperty(f.field_key)) {
			return;
		}

		const val: any = values[f.field_key];
		if (f.filter_type === FilterType.numeric) {
			const numericPredicates = getNumericPredicate(page, f, val);
			if (numericPredicates?.predicate) {
				predicates.push(numericPredicates.predicate);
			}
			if (numericPredicates?.linqPredicate) {
				linqPredicates.push(numericPredicates.linqPredicate);
			}
		}
		if (f.filter_type === FilterType.date) {
			const numericPredicates = getNumericPredicate(page, f, val ?? []);
			if (numericPredicates?.predicate) {
				predicates.push(numericPredicates.predicate);
			}
			if (numericPredicates?.linqPredicate) {
				linqPredicates.push(numericPredicates.linqPredicate);
			}
		}
		if (
			f.filter_type === FilterType.list ||
			f.filter_type === FilterType.list_not_in
		) {
			if (val) {
				const propertyAccessor = linqPropertyAccessors(
					page,
					f.field_key
				);

				const predicate = getListPredicate(f, val);

				if (predicate) {
					predicates.push(predicate);
					const isValArray = isMatchingAgainstArray(
						page,
						f.field_key
					);
					if (isValArray) {
						if (f.filter_type === FilterType.list_not_in) {
							linqPredicates.push(
								(item: any) =>
									linqValueAccessors(
										page,
										f.field_key,
										val
									).filter(
										Set.prototype.has,
										new Set(propertyAccessor(item))
									).length === 0
							);
						} else {
							linqPredicates.push(
								(item: any) =>
									linqValueAccessors(
										page,
										f.field_key,
										val
									).filter(
										Set.prototype.has,
										new Set(propertyAccessor(item))
									).length > 0
							);
						}
					} else {
						if (f.filter_type === FilterType.list_not_in) {
							linqPredicates.push(
								(item: any) =>
									!linqValueAccessors(
										page,
										f.field_key,
										val
									).includes(propertyAccessor(item))
							);
						} else {
							linqPredicates.push((item: any) =>
								linqValueAccessors(
									page,
									f.field_key,
									val
								).includes(propertyAccessor(item))
							);
						}
					}
				}
			}
		}
		if (f.filter_type === FilterType.alphanumeric) {
			if (val) {
				const predicate = `contains(tolower(${
					f.field_path
				}), '${encodeURIComponent("" + val).toLowerCase()}')`;
				predicates.push(predicate);
				linqPredicates.push(getAlphaNumericLingPredicate(page, f, val));
			}
		}
		if (f.filter_type === FilterType.exact) {
			const predicate = `${f.field_path} eq ${encodeURIComponent(
				JSON.stringify(val)
			)}`;
			predicates.push(predicate);
			linqPredicates.push(getExactLinqPredicate(page, f, val));
		}
	});

	return {
		predicates,
		linqPredicates,
	};
};

const getDateFromPlaceHolder = (placeHolder: string): string => {
	// | separator means add, ! means remove
	const parts = placeHolder.split(/[|!]+/);
	const operator = placeHolder.split("!").length === 2 ? -1 : 1;
	if (parts.length === 2) {
		const years = parts[1].split("Y");
		if (years.length === 2) {
			const formattedDate = format(
				addYears(new Date(), operator * Number(years[0])),
				"yyyy-MM-dd'T'HH:mm:ss'Z'"
			);
			return `"${formattedDate}"`;
		}
		const months = parts[1].split("M");
		if (months.length === 2) {
			const formattedDate = format(
				addMonths(new Date(), operator * Number(months[0])),
				"yyyy-MM-dd'T'HH:mm:ss'Z'"
			);
			return `"${formattedDate}"`;
		}
		const weeks = parts[1].split("W");
		if (weeks.length === 2) {
			const formattedDate = format(
				addWeeks(new Date(), operator * Number(weeks[0])),
				"yyyy-MM-dd'T'HH:mm:ss'Z'"
			);
			return `"${formattedDate}"`;
		}

		const formattedDate = format(
			addDays(new Date(), operator * Number(parts[1])),
			"yyyy-MM-dd'T'HH:mm:ss'Z'"
		);
		return `"${formattedDate}"`;
	}

	const formattedDate = format(new Date(), "yyyy-MM-dd'T'HH:mm:ss'Z'");
	return `"${formattedDate}"`;
};

export const parseFromTemplate = (
	template: string,
	identity?: Identity
): string => {
	const dates = template.match(/"(NOW\(\).*?)"/g);
	dates?.forEach((line) => {
		const placeHolder = line.replace(/(^"|"$)/g, "");
		template = template.replace(line, getDateFromPlaceHolder(placeHolder));
	});

	if (identity) {
		const userPlaceHolder = template.match(/"(USER\(\).*?)"/g);
		userPlaceHolder?.forEach((match) => {
			template = template.replace(
				match,
				`{ "id": ${identity.id}, "name": "${identity.first_name} ${identity.last_name}"}`
			);
		});
	}

	return template;
};

export const setSelectedFilterValues = (
	filtersData: Filter[],
	storedFilters: string
) => {
	let obj: { [k: string]: any } = {};

	if (!storedFilters) {
		return obj;
	}

	if (storedFilters) {
		obj = JSON.parse(storedFilters);
		filtersData.forEach((f: Filter) => {
			if (f.filter_type === FilterType.date) {
				const values = obj[f.field_key]?.map((v: string) => {
					if (v) {
						return new Date(v);
					}
					return v;
				});
				obj[f.field_key] = values;
			}
		});

		return obj;
	}
};
